<template>
    <!--中奖提示-->
    <div class="mask">
        <div class="blin"></div>
        <div class="imgCard"> 
            <div class="titleCard">
                <div v-if="isPrize">恭喜您抽中本场奖励</div>
                <div v-else>很遗憾您未中奖</div>
            </div>
            <img :src=prizeImg alt="">
            <div class="my-gift" v-if="isPrize">
                <div class="gift-title">获得以下礼物</div>
                <div class="gift-item">
                    <div v-for="item in giftArray" :key="item.name" >
                       <img :src="item.image" alt="" class="gift-image"> <span>X{{ item.amount }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tips",
    props: ['prize'],
    data() {
        return {
            isEmpty: false,
            isPrize:this.prize.isPrize,
            prizeImg: this.prize.img,
            giftArray:this.prize.prizeInfo
        }
    },
    mounted() {
        // this.isEmpty = this.list.every(i => i.type == 2);
        // this.initJquery()
    },
    methods: {
        initJquery() {
            $(function () {
                var $mask = $(".mask"),//红包遮罩层
                    $winning = $(".winning"),//红包
                    $card = $(".card"),
                    $close = $(".close");
                $winning.addClass("reback");
                setTimeout(function () {
                    $card.addClass("pull");
                }, 500);
            });
        }
    },
}
</script>

<style scoped>
.center {
    justify-content: center;
}
.imgCard{
    position: absolute;
    top: 36%;
    left: 3.4rem;
    width: 4rem;
    height: 4rem;
    /* z-index: 10; */
}
.imgCard img{
    width: 80%;
}
.titleCard{
    font-size: .4rem;
    color: #fff;
    text-align: center;
}
.my-gift{
    font-size: .4rem;
    color: #fff;
    text-align: center;
}
.my-gift .gift-image{
    width: 1.2rem;
}
.my-gift span{
    font-size: .3rem;
}
.gift-item{
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.mt8 {
    margin-top: .2rem;
}
</style>
